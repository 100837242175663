<template>
  <section class="cont">
     <!-- 面包屑 -->
     <el-row class="crumbs-box">
        <el-breadcrumb separator-class="el-icon-arrow-right">
           <el-breadcrumb-item>OMS</el-breadcrumb-item>
           <el-breadcrumb-item>酒店管家</el-breadcrumb-item>
           <el-breadcrumb-item>工单管理</el-breadcrumb-item>
           <el-breadcrumb-item>任务计划</el-breadcrumb-item>
        </el-breadcrumb>
     </el-row>
     <el-row class="content-box">
        <!-- 查询框   -->
        <el-row class="search-box">
           <el-row class="search-row">
              <el-row class="search-item">
                 <label>房间：</label>
                 <el-input  class="width-220"  placeholder="请输入房间号"  v-model="roomNo"></el-input>
              </el-row>
              <el-row class="search-item">
                 <label>员工姓名：</label>
                 <el-input  class="width-220" placeholder="请输入员工姓名" v-model="employName"></el-input>
              </el-row>
              <el-row class="search-item">
                 <label>时间：</label>
                 <date-packer class="width-220" :datePacker="dateValue" @setDatePacker="getDatePacker"></date-packer>
              </el-row>
              <el-button class="m-bottom-5 bg-gradient" type="primary"  @click="handleQuery(true)"><span v-text="$t('msg.search')">搜索</span></el-button>
              <el-button class="m-bottom-5" type="primary"  @click="handleQuery(false)" plain><span v-text="$t('msg.reset')">重置</span></el-button>
           </el-row>
        </el-row>
        <!-- 主体内容 -->
        <el-row class="table-box">
           <!-- 表格按钮 -->
           <el-row class="table-head-btn">
              <el-row class="right-btn">
                 <el-button class="bg-gradient" type="primary" icon="el-icon-plus"  @click="handleAddEdit('add')"><span>创建任务</span></el-button>
              </el-row>
           </el-row>
           <!-- 主体内容 -->
           <el-table
          ref="mainTable"
          :data="tableData"
          style="width: 100%"
          :stripe="true"
          border fit>
        <el-table-column :label="$t('msg.Serial')" width="60">
          <template slot-scope="scope">
            {{scope.$index + 1}}
          </template>
        </el-table-column>
        <el-table-column prop="statisticsDate" label="时间" min-width="200"></el-table-column>
        <el-table-column label="任务类型" min-width="110">
           <template slot-scope="scope">
              {{ scope.row.taskType | filterTaskType(that) }}
           </template>
        </el-table-column>
        <el-table-column prop="employDuty" label="职务" min-width="200"></el-table-column>
        <el-table-column prop="employName" label="负责人" min-width="200"></el-table-column>
        <el-table-column prop="employPhone" label="联系方式" min-width="140"></el-table-column>
        <el-table-column prop="employOrgName" label="所属部门" min-width="200"></el-table-column>
        <el-table-column label="操作" min-width="240" sortable fixed="right">
          <template slot-scope="scope">
            <el-button  type="text" @click="handleAddEdit('edit', scope.row)" >
              <el-link type="primary" v-text="$t('msg.edit')">编辑</el-link>
            </el-button>
            <el-button type="text" style="color: #F56C6C;" @click="handleDel(scope.row.id)" >
              <el-link type="primary" v-text="$t('msg.remove')">删除</el-link>
            </el-button>
          </template>
        </el-table-column>
      </el-table>
          <!-- 分页 -->
         <pagination :total="total" :page-size="limit" @handleSizeChangeSub="changePageNum" @handleCurrentChangeSub="changeCurrPage"/>
        </el-row>
    </el-row>
  </section>
</template>

<script>
   import { mapState } from 'vuex'
   import { urlObj } from '@/api/interface'
   import {getDict} from '@/common/js/common'
   export default {
      data(){
         return{
            that: this,
            tableData: [],       // 表格数据
            loading: true,       // 加载效果
            total: 0,            // 数据总数
            isAcceptance: false, // 是否显示验收弹窗
            /* 查询参数 */
            limit: 0,            // 每页数
            page: 1,             // 当前页
            roomNo:'',           // 房间号
            employName:'',       // 员工姓名
            dateValue: [],       // 设备新增时间段
            beginTime: '',       // 设备新增开始时间
            endTime: '',         // 设备新增结束时间
            /* 提示文本 */
            confirm: '确定',
            cancel: '取消',
            prompt: '提示！',
            update_success: "修改成功！",
            del_success: '删除成功！',
            acceptance_success: '验收成功！',
            hotelName:'',
            index:''              //默认酒店
         }
      },
      mounted() {
         getDict(['task-type'])
         this.limit = sessionStorage.getItem('pageSize') * 1
         this.getTaskList()
      },
      computed:{
        ...mapState(['hotelInfo', 'dictData']),
      },
      methods: {
         //任务表格数据
         getTaskList(){
            const param = {
               handleRoom: this.roomNo,
               employName: this.employName,
               beginDate: this.beginTime,
               endDate: this.endTime,
               page: this.page,
               limit: this.limit,
               hotelId: this.hotelInfo.id
            }
            const url = urlObj.planTaskPaging
            this.$axios.post(url, param).then(res => {
               if (res.success) {
                  this.loading = false
                  this.total = res.total
                  this.tableData = res.records
               }
            })
         },
         // 搜索工单
         handleQuery(bool){
            if (bool) return this.getTaskList()
            this.roomNo = ''
            this.employName = ''
            this.beginTime = ''
         },
         // 改变每页数
         changePageNum(num){
            this.limit = num
            this.getTaskList()
         },
         // 改变当前页
         changeCurrPage(num){
            this.page = num
            this.getTaskList()
         },
         // 编辑任务
         handleAddEdit(action, row){
            if (action === 'edit') sessionStorage.setItem('taskInfo', JSON.stringify(row))
            this.$router.push({path: '/create_task', query: {action}})
         },
         // 删除工单
         handleDel(id){
            this.$confirm('此操作将永久删除此任务, 是否继续?', '提示', {
               confirmButtonText: '确定',
               cancelButtonText: '取消',
               type: 'warning'
            }).then(() => {
               const url = urlObj.scheduledtask
               const param = { id, isDelete:'YES' }
               this.$axios.post(url, param).then(() => {
                  this.getTaskList()
                  this.$message({ type: 'success', message: '删除成功!' })
               })
            }).catch(() => {})
         },
         // 按新增时间段过滤
         getDatePacker(val){
            this.dateValue = val
            if (this.dateValue) {
               this.beginTime = this.dateValue[0]
               this.endTime = this.dateValue[1]
            } else {
               this.beginTime = ''
               this.endTime = ''
            }
            this.dateType = ''

         },
      },
      watch: {
         hotelInfo: {
            handler(newVal, oldVal) {
               if (newVal.id !== oldVal.id && oldVal) {
                  this.getTaskList()
               }
            },
            deep: true
         }
      },
      filters: {
         filterTaskType(val, that){
            if (val) return that.dictData['task-type'][val]
         }
      }
   }
</script>

<style scoped lang="scss">
.label{font-size: 14px;}
  .pop-top{display: none;}
 /deep/ .el-popper .el-picker-panel__body-wrapper .el-picker-panel__sidebar{display: none !important;}
</style>
